
.sim-container {
	width: calc(50% - 25px);
	height: calc(100% - 20px);
}

#sim-canvas {
	position: relative;
	margin-top: 10px;
	z-index: 2;
	height: 100%;
	width: 100%;
}

#sim-stats {
	position: absolute;
	bottom: 0;
	right: 0;
}

.run-button {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 10;
	left: 15px;
	bottom: -5px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
	padding: 0;
	box-sizing: border-box;
	width: 60px;
	height: 60px;
	border: 6px solid #fff;
	border-radius: 50%;
	color: #f5f5f5;
	text-align: center;
	text-decoration: none;
	background-color: rgba(0, 0, 0, 0.5);
	transition-property: background-color, box-shadow, text-shadow;
	transition-duration: 0.3s;
	transition-timing-function: ease;
}

.run-button::before {
	font-family: 'Font Awesome 5 Free';
	font-size: 2em;
	font-weight: bold;
}

.run-button:not(.running)::before {
	content: '\f04b';  /* play icon */
	padding-left: 4px;
}

.run-button.running::before {
	content: '\f04d';  /* stop icon */
	padding-left: 0px;
}

.run-button:disabled::before {
	content: '\f252';  /* hourglass icon */
	padding-left: 0px;
}

.run-button:enabled:hover {
	background-color: rgba(0, 0, 0, 0.8);
	box-shadow: 0px 0px 10px rgba(255, 255, 100, 1);
	text-shadow: 0px 0px 10px rgba(255, 255, 100, 1);
}

.run-button:disabled {
	color: grey;
	border-color: grey;
}

.robot-info {
	position: absolute;
	right: 10px;
	bottom: 10px;
	z-index: 20;
	display: none; /* flex; */
	justify-content: center;
	align-items: center;
	width: 1.5em;
	height: 1.5em;
	color: white;
	opacity: 0.1;
	cursor: pointer;
	transition: opacity 0.1s ease;
	border: 1px solid white;
	border-radius: 50%;
}

.robot-info:hover {
	opacity: 0.7;
}
